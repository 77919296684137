module.exports = {
   "menu.adminPanel": "admin panel",
   "menu.Home": "Home",
   "menu.HomeOne": "home one ",
   "menu.HomeTwo": "home two ",
   "menu.HomeThree": "home three ",
   "menu.fashion": "Fashion",
   "menu.shop": "shop",
   "menu.gadgets": "gadgets",
   "menu.accessories": "accessories",
   "menu.categories": "categories",
   "menu.pages": "pages",
   "menu.session": "session",
   "menu.contact us": " contact us",
   "menu.men": "men",
   "menu.women": "women",
   "menu.t-shirts": "t-shirts",
   "menu.jeans": "jeans",
   "menu.shoes": "shoes",
   "menu.wallet": "wallet",
   "menu.westernWear": "western wear",
   "menu.ethnicWear": "ethnic wear",
   "menu.sportsWear": "sports wear",
   "menu.headPhones": "headPhones",
   "menu.laptop": "laptop",
   "menu.speaker": "speaker",
   "menu.watch": "watch",
   "menu.jewellery": "jewellery",
   "menu.belts": "belts",
   "menu.handBag": "handBag",
   "menu.productDetail": "product detail",
   "menu.cart": "cart",
   "menu.checkout": "checkout",
   "menu.payment": "payment",
   "menu.blogDetail": "blog detail",
   "menu.userProfile": "user profile",
   "menu.faq": "faq",
   "menu.404Page": "404 page",
   "menu.signIn": "sign in",
   "menu.register": "register",
   "menu.forgotPassword": "forgot password",
   "menu.thankYou": "thank you",
   "menu.aboutUs": "about us",
   "menu.termsAndConditions": "terms and conditions",
   "menu.privacyPolicy": "privacy policy",
   "footer.aboutUs": "about us",
   "footer.termsAndCondition": "terms and condition",
   "footer.PrivacyPolicy": "privacy policy",
   "footer.Faq": "FAQ",
   "footer.contactUs": "contact us",
   "footer.signIn": "sign in",
   "footer.register": "register",
   "footer.forgetPassword": "forget password",
   "footer.thankYou": "thank you",
   "footer.women": "women",
   "footer.men": "men",
   "footer.accessories": "accessories",
   "footer.gadgets": "gadgets",
   "footer.facebook": "facebook",
   "footer.twitter": "twitter",
   "footer.youtube": "youtube",
   "footer.googlePlus": "google plus",
   "admin.reports": "reports",
   "admin.invoices": "invoices",
   "admin.products": "products",
   "admin.profile": "profile",
   "admin.productAdd": "product add",
   "admin.goToHome": "go to home"
}
